import React, {useState, useEffect, Fragment}  from 'react';
import moment from 'moment';
import {PublishSubscribe} from '../../../services';
import {addChildToArray, removeChildFromArray, updateObjectBasedOnKey, hcGetIconForSiteOrMatch} from '../../../utils'
import _ from 'lodash'
import {useHistory} from 'react-router-dom'
import {useMySitesPanelChange} from '../useMySitesPanelChange.js'
import {setSidebarPositionData} from "./utils/positionSidebar"

export default function SiteMaterials({passedMaterial, switchSidePanel, hasGroup, sortMode, isHighlighted, setSelected, openSettingsModal, canEditSite, deleteMaterial, removeSelf, isMobile, isBrowser, hasExternalAccess, isSeedUser}){

	let groupsListMode = false;
	let [material, updateMaterial] = useState(_.cloneDeep(passedMaterial));
	let [unreads, updateUnreads] = useState(passedMaterial.unreadExternalsCountNoArchive ? passedMaterial.unreadExternalsCountNoArchive.split(',') : []);
	let mySitesPanels = useMySitesPanelChange();

	let PS = PublishSubscribe.usePublishSubscribe({
		origin: "MySites/Sidebar/SiteMaterials/"+material.idsite_resource,
  	topic: "HC/materials",
  	identifier: {idsite_resource: material.idsite_resource},
  	callback: (message) => {
			console.log("SiteMaterials handleMessage message:", message);
			message = JSON.parse(message);

			console.log("SiteMaterials handleMessage message:", message);

			//Update stats of materials where needed
		  if (message.type == "updated-material"){
				//let clone_material = _.cloneDeep(material);
				updateMaterial((previousState) => updateObjectBasedOnKey(previousState, message.data));	
			} else if (message.type == "new-messages"){
				updateUnreads((previousState) => addChildToArray(previousState, message.data, true));
			} else if (message.type == "read-message"){
				updateUnreads(previousState => removeChildFromArray(previousState, message.data));
			}
		}

	})

	useEffect(() => {
		console.log("Material.status updated to " + material.status);
		if (material.archived == 1 || material.status == "deleted"){
			removeSelf(material.idsite_resource)
		}

	}, [material.archived, material.status])


	/*
	*	Rendering Functions
	*/

	let daysUntilExpiration = () => {
		let expiration = moment(material.expiration_date);

		let daysDiff = expiration.diff(moment(), "days");

		let ret = daysDiff + " day";
		return (daysDiff > 1 ? ret + "s" : ret);
	}

	/*let renderRoutes = () => {
		let {routesCount} = material;

		let style = "badge-pale badge-light";
		if (routesCount > 0) style = "badge-pale badge-success";

		return(
			<span className={"badge " + style} style={{fontWeight: 600}}>{routesCount} Routes</span>
		)
	}*/ //Unused

		let renderMessagesDot = () => {
		if (unreads.length > 0) {return <span className="badge badge-dot badge-primary mb-2 ml-1"></span>;}
		else {return null;}
	}

	let renderMatches = () => {

		let {interactionsCount, matchesPanelCount} = material;

		let style = "badge-pale badge-light";
		if (matchesPanelCount > 0) style = "badge-glass badge-success";

		return(
			<span className={"badge " + style}>{matchesPanelCount} Matches</span>
		)
	}




	let renderMessages = () => {


	/*	let {matchesPanelCount} = material;

		let style = "badge-pale badge-light";
		if (unreads.length > 0) style="badge-glass badge-primary"
		else if (matchesPanelCount > 0) style = "badge-pale badge-primary"

		return(
			<span className={"badge " + style}>
				{unreads.length} Messages
			</span>
		)*/
	}

	let renderName = () => {
		if (sortMode == "material")
			return <Fragment>{material.name}</Fragment>
		else {
			return <Fragment>{material.resource}</Fragment>
		}
	}

	let renderPhase = () => {

		let {archived, archived_date, iddiscoverymode, status, isBlasted, isSponsoredMaterial, has_ever_been_external} = material;

		if (!hasExternalAccess){
			return null
		} else if (isSponsoredMaterial){
      return <span className="badge badge-pale badge-warning">Sponsored</span>;
		} else if (archived){
      return <span className="badge badge-pale badge-secondary">Archived</span>;
    } else if (has_ever_been_external && status == "internal" && iddiscoverymode == 2){
    	return <span className="badge badge-pale badge-warning">Post Expired</span>
  	} else if (has_ever_been_external && status == "internal" && iddiscoverymode == 1){
    	return <span className="badge badge-pale badge-warning">Search Expired</span>
    } else if (iddiscoverymode == 2 && status == "external" && !isBlasted){
      return <span className="badge badge-pale badge-primary">Posted</span>
    } else if (iddiscoverymode == 2 && status == "external" && isBlasted){
      return <span className="badge badge-pale badge-success">SuperBlast</span>
    } else if (iddiscoverymode == 1 && status == "external"){
      return <span className="badge badge-pale badge-primary">Bid Search</span>
    } else if (iddiscoverymode == 1 && status == "internal"){
      return <span className="badge badge-pale badge-secondary">Bid</span>
    } else {
      return <span className="badge badge-pale badge-secondary">Not Posted</span> 
    }
	}

	let renderIcon = () => {
		if (sortMode !== "material") return null;
		else {
			return <span class="marker float-left mr-2">
				<img src={hcGetIconForSiteOrMatch(material, "site")} />
			</span>
		}
	}

	{/*let renderMenu = () => {
		let {iddiscoverymode, idsite_resource, status, archived} = material;

		if (!canEditSite) return null;
		return(<>
			<span className="btn btn-xs btn-round btn-outline-primary dropdown-toggle px-2 py-0 mt-1" data-toggle="dropdown"><i className="fa fa-cog fs-12 pr-1" aria-hidden="true"></i>Material</span>
				<div className="dropdown-menu border">
					<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "matchCriteria", material)}>General Info</a>
					<a className="dropdown-item text-dark" data-toggle="modal" href="#" onClick={() => switchSidePanel("sharedDetails", {idsite_resource: material.idsite_resource, groupsListMode})}>
	        	{material.detail ? "Enter" : "Enter"} Details
	    		</a>
	      	<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "searchRadius", material)}>Search Radius</a>
	      	<div class="dropdown-divider"></div>
	    		{status == "internal" ? <>
      			<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "goExternal", material)}>{iddiscoverymode == 2 ? "Post" : "Search"} Externally</a>
      		</> : null }
	    		{status == "external" ? <>
	    			<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "addMonth", material)}>Add month to Post</a>
	    			<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "goInternal", material)}>{iddiscoverymode == 2 ? "Remove Post" : "End Search"}</a>
	    		</>: null}
	    		{archived ? 
						<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "unarchiveMaterial", material)}>Recover Material</a>
	  			:
	  				<a className="dropdown-item text-dark" href="#" onClick={() => openSettingsModal(idsite_resource, "archiveMaterial", material)}>Archive Material</a>
	  			}
	  			<a className="dropdown-item text-dark" href="#" onClick={() => deleteMaterial(material)}>Delete Material</a>
      </div>
    </>)
	}*/}

	let renderQuantity = () => {
		let {type, quantity} = material;

		let displayType = (type ? type.split('/')[0] == "Surplus" ? "Surplus" : "Short" : "");

		return "— " + displayType + " " + parseInt(quantity).toLocaleString() + "s CYs";
	}

	let goToMatchesPanel = () => {
		//alert("Setting matchesPanel with idsiteresource " + material.idsite_resource);
		setSidebarPositionData(material);
		localStorage.setItem("matchesPanel", JSON.stringify({idsite: material.idsite, idsiteresource: material.idsite_resource, groupsListMode}))
		mySitesPanels.swap("matches", "material-info")
	}

	let openMaterialInfo = () => {
		//localStorage.setItem("matchesPanel", JSON.stringify({idsite: material.idsite, idsiteresource: material.idsite_resource, groupsListMode}))
		localStorage.setItem("matchesPanel", JSON.stringify({idsite: material.idsite, idsiteresource: material.idsite_resource, groupsListMode}))
		mySitesPanels.remove("material-info");
		//Delay new material info opening to ensure proper swapping of active material
		setTimeout(() => {mySitesPanels.add("material-info");}, 10)
	}

	let availableRange = () => {

		if (material.perennialEndDate === 0 || material.perennialEndDate === "0"){
			return moment(material.available_date).add('days', 1).format("MMM 'YY") + "-" + moment(material.available_end_date).add('days', 1).format("MMM 'YY")
		} else {
			return moment(material.available_date).add('days', 1).format("MMM 'YY") + " onward"
		}
	}


	if (material.archived == 1 || material.status == "deleted") return null;
	else return(
		<div className={"row mt-1 pb-3 mb-2" + (isMobile ? "border-bottom" : "")}>
			<div class="col-12 text-dark pl-4 py-1 mb-0">
				<span class="mb-0 fw-600">{renderIcon()}{renderName()}</span>
				<span class="my-1 small text-light">{renderQuantity()} {renderMessagesDot()}</span>
				<span class="my-1 small text-light float-right">{availableRange()}</span>
			</div>

			{hasExternalAccess ? <div class={"lh-1 mr-4 mt-1 col-" + (isMobile ? "3" : "2")}>
				<p class="mb-2">{renderPhase()}</p>
			</div> : null}
			<div class={"lh-1 mr-4 mt-1 ml-auto col" + (hasExternalAccess ? (isMobile ? "3" : "2") : isMobile ? "9" : "12" )}>
				<p class="mb-2">{renderMatches()}</p>
			</div>
			{/*hasExternalAccess ? <div class={"lh-1 mr-4 mt-1 ml-auto col" + (hasExternalAccess ? (isMobile ? "3" : "2") : isMobile ? "6" : "3" )}>
				<p class="mb-2">{renderMessages()}</p>
			</div> : null*/}

			{isBrowser ? 
				<div class="mr-4 ml-auto">
					<button class="btn btn-xs btn-round btn-outline-primary float-right px-2 py-0 mt-1" id={"view-material-info-button-"+material.idsite_resource} onClick={() => openMaterialInfo()}>Material Details</button>
				</div>

			:
				<button class="btn btn-block btn-xs btn-round btn-outline-primary py-0 mx-5 mt-4 mb-3" id={"view-material-info-button-"+material.idsite_resource} onClick={() => openMaterialInfo()}>Material Details</button>

			}

			{isBrowser ? 
			<div class="mr-4 ml-auto">
				<button class="btn btn-xs btn-round btn-primary float-right px-2 py-0 mt-1" id={"view-matches-button-"+material.idsite_resource} onClick={() => goToMatchesPanel()}>View Matches</button>
			</div>

			:
				<button class="btn btn-block btn-xs btn-round btn-primary py-0 mx-5 mt-4 mb-3" id={"view-matches-button-"+material.idsite_resource} onClick={() => goToMatchesPanel()}>View Matches</button>

			}
		</div>
	)

}
